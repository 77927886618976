import Vue from 'vue'
import Router from 'vue-router'
import {getUrlParameter, removeURLParameter, notEmpty} from '../constant'
import store from '../store'
import { getToken, setToken } from '../util/auth'

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location){
  return originalPush.call(this, location).catch(err => err)
};


const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      component: () => import('@/components/layout/Layout.vue'),
      redirect: '',
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/views/pages/home/Home.vue'),
        },
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/login/Login.vue'),
        },
        {
          path: '/upload',
          name: 'upload',
          component: () => import('@/views/pages/upload/CustomForm.vue'),
        },
        {
          path: '/refresh',
          name: 'refresh',
          component: () => import('@/views/pages/home/Refresh.vue'),
        },
        {
          path: '/editItem/:id',
          name: 'edit',
          component: () => import('@/views/pages/upload/Edit.vue'),
        },
      ]
    },
    {
      path:'*',redirect: { name: 'home'}
    },
  ]
})



router.beforeEach ( async (to, from, next) => {
  const hasToken = getToken();
  let error = getUrlParameter(window.location.search,'error');
  // 判断url中是否有token
  let signupToken = getUrlParameter(window.location.search,'paramsDavasvssegrgre');
  let loginToken = getUrlParameter(window.location.search,'loginToken');
  let resetPwdtoken = getUrlParameter(window.location.search,'resetPwdtoken');
  let urlToken = notEmpty(signupToken) || notEmpty(loginToken) || notEmpty(resetPwdtoken);

  if(error){
    window.location.href='/'; // 避免死循环
  }else{
    if(hasToken || urlToken){
      if (hasToken) {
        if (to.name === 'login') {
          // if is logged in, redirect to the home page
          next({ path: '/' })
        } else {
          store.dispatch('getInfo').then(response => { // 拉取user_info
            console.log(response)
            next()
          }).catch(() => {
            // remove token and go to login page to re-login
            store.dispatch('resetToken').then(() => {
              next(`/login?redirect=${to.path}`)
            })
          })
        }
      }else{
        next()
      }
    }
    else {
      if(to.matched.some(m => m.meta.requireAuth)){
        next( {name: 'login'})
      }else {
        next();
      }
    }
  }

})


export default router

