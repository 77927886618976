import Vue from 'vue'
import App from './App.vue'

import store from './store'

import '@/icons'

import registerServiceWorker from './util/registerServiceWorker';

import router from './router/router'

import myLoading from '../src/components/loading'
Vue.use(myLoading,{
  icon:require('../src/assets/x-img/test-flight-logo.png'),
  progressColor:'blue'
})


Vue.config.productionTip = false;



import { BContainer,BRow, BCol, BLink, BButton} from 'bootstrap-vue'
Vue.component('b-container', BContainer)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-link', BLink)
Vue.component('b-button', BButton)
import {BModal} from 'bootstrap-vue'
Vue.component('b-modal', BModal)

import {BVModalPlugin} from 'bootstrap-vue'
Vue.use(BVModalPlugin)

import InfoModal from "./components/modal/InfoModal";
Vue.component('InfoModal', InfoModal);


new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')

registerServiceWorker();

