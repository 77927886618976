
import request from '@/util/request'

import {API_BASE_URL} from '../constant';
import {getToken} from "./auth";

export const getCurrentUser = () => requestWapper( "/user/me",'GET', null);

//auth
export const login = (loginRequest) => requestWapper( "/auth/login",  'POST', loginRequest);
export const logout = () => requestWapper( "/auth/logout",  'GET', null);
export const ipaVersionAdd = (data) => requestWapper( "/front/ipaVersion/add",  'POST', data);
export const ipaVersionDetail = (id) => requestWapper( "/front/ipaVersion/"+id,  'GET', null);
export const ipaVersionUpdate = (id, data) => requestWapper( "/front/ipaVersion/"+id,  'POST', data);


const requestWapper = (pathInfo, method, data) => {

  let url = API_BASE_URL + "/api" + pathInfo;
  if (method === 'GET' && data)
    url = url + "?" + new URLSearchParams(data);

  let headers = {
    'Content-Type': 'application/json', //设置跨域头部
    "Authorization": getToken()
  };
  return request({url: url, method: method, data: data, headers: headers})
    .then(res => {
        if (res.code !== 0) {
          return Promise.reject(res);
        }
        return res
      }
    ).catch(err => {
      if(err.code === 403 && !window.location.href.includes('403')){
        window.location = '/403'
      }
      console.log(err);
      return err;
    });
}


