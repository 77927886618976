const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  email: state => state.user.email,
  userId: state => state.user.userId,
  country: state => state.user.country,

  // isMobile: state => state.app.isMobile,
  // isPad: state => state.app.isPad,
  device: state => state.app.device,
}


export default getters
