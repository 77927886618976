import store from '../store'
import axios from 'axios'

import { getToken } from '../util/auth'

// create an axios instance
const service = axios.create({
  baseURL: '', // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 1200000 // request timeout
})

// request拦截器
// service.interceptors.request.use(config => {
//   // if (store.getters.token) {
//   //   config.headers['Authorization'] = getToken()
//   // }
//   return config
// }, error => {
//   Promise.reject(error)
// })

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    //console.log(res)
    if (res.code !== 0) {
      return Promise.reject(res)
    }
    return res
  },
  error => {
    //console.log(error.response)
    return Promise.reject(error.response)
  },
)
export default service
