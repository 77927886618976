
let g_API_BASE_URL = '';
let g_UI_BASE_URL = '';

if(process.env.NODE_ENV==='development'){
  let devHost =  process.env.VUE_APP_DEV_HOST;
  if(process.env.MODE_TYPE === '1'){
    devHost =  process.env.VUE_APP_t_DEV_HOST;
  }
  g_API_BASE_URL = 'http://'+devHost+':8080';
}else{
  g_API_BASE_URL =  process.env.VUE_APP_API_BASE_URL;
  g_UI_BASE_URL =  process.env.VUE_APP_BASE_URL;
}

export const API_BASE_URL = g_API_BASE_URL;


export function notEmpty(value){
  return value !== 'undefined' && value !== undefined && value !== null && value !== ''
}

export function empty(value){
  return value === 'undefined' || value === undefined || value === null || value === ''
}
export function getUrlParameter(search,name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

  let results = regex.exec(search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}


export function  removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  let urlparts = url.split('?');
  if (urlparts.length >= 2) {

    let prefix = encodeURIComponent(parameter) + '=';
    let pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0;) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}
