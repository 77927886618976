import { login, logout, getCurrentUser } from '../../util/APIUtils'
import { getToken, setToken, removeToken } from '../../util/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    email: '',
    avatar: '',
    userId: '',
    emailVerified: false,
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    SET_EMAILVERIFIED: (state, emailVerified) => {
      state.emailVerified = emailVerified
    },

  },

  actions: {
    // user login
    login({commit}, userInfo) {
      commit('SET_TOKEN', '');
      removeToken();
      const {email, password} = userInfo;
      return new Promise((resolve, reject) => {
        login({email: email.trim(), password: password}).then(response => {
          try{
            console.log('response', response);
            let accessToken = response.data.token.accessToken;
            let tokenType = response.data.token.tokenType;
            commit('SET_TOKEN', accessToken);

            const {id, name, email, imageUrl, emailVerified, country, provider} = response.data.user;
            commit('SET_USERID', id);
            commit('SET_NAME', name);
            commit('SET_EMAIL', email);
            commit('SET_AVATAR', imageUrl);
            commit('SET_EMAILVERIFIED', emailVerified);
            setToken(tokenType + ' ' + accessToken);
            resolve(response);
          }catch (e) {
            resolve(response);
            // return response
          }
        }).catch(error => {
          reject(error);
        })
      })
    },
    getInfo({commit, state}) {
      return new Promise((resolve, reject) => {
        getCurrentUser().then(response => {
          const {data, code} = response;
          if (code !== 0) {
            reject('Verification failed, please Login again.');
          }

          const {id, name, email, imageUrl, emailVerified, country,receiveNews,blacklist, provider} = data;
          commit('SET_USERID', id);
          commit('SET_NAME', name);
          commit('SET_EMAIL', email);
          commit('SET_AVATAR', imageUrl);
          commit('SET_EMAILVERIFIED', emailVerified);
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    logout({commit, state}) {
      return new Promise((resolve, reject) => {
        logout().then(() => {

          commit('SET_TOKEN', '');
          commit('SET_USERID', '');
          commit('SET_NAME', '');
          commit('SET_EMAIL', '');
          commit('SET_AVATAR', '');
          commit('SET_EMAILVERIFIED', false);

          removeToken();
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // remove token
    resetToken({commit}) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '');
        removeToken();
        resolve()
      })
    }
  }
}

export default user

