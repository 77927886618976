import Cookies from 'js-cookie'

const app = {
  state: {
    language: '',
  },
  mutations: {
    SET_LANGUAGE: (state, language) => {
      state.language = language;
    },
  },
  actions: {
    setLanguage({ commit }, language) {
      Cookies.set('language', language);
    },

  }
}

export default app
