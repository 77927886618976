<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <b-modal id="info-modal" centered hide-footer :size="modalSize"  hide-header-close>
    <template v-slot:default="{cancel}">
    <div class="text-center">
      <div>
        <span class="mt-1 info-msg" style="display: block; margin:0 auto">{{message}}</span>
      </div>
      <div class="mt-4 mb-3">
        <b-button variant="warning" v-on:click="closeModal">{{buttonText == null ? $t('ConfirmModal.confirm') : buttonText}}</b-button>
      </div>
    </div>
    </template>
  </b-modal>
</template>

<script>
  export default {
    name: "InfoModal",
    components:{
    },
    data() {
      return {
        message: '',
        buttonText: '',
      }
    },
    computed:{
      modalSize(){
        if(this.message && this.message.length>50)
          return 'lg';
        else
          return '';
      }
    },
    methods:{
      closeModal(){
        this.$bvModal.hide('info-modal');
        this.$emit('child');
      },
      showModal(message,buttonText) {
        this.message = message;
        this.buttonText = buttonText;
        this.$bvModal.show('info-modal');
      },
    }
  }
</script>


<style scoped>
  .info-msg {
    font-family: verdana;
    font-size: 20px;
    font-weight:normal;
  }
</style>
