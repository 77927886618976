<template>
  <div id="app" v-cloak>
<!--    class index and body is from mercury-->
    <router-view :key="$route.fullPath" />
  </div>
</template>


<script>
  import '@/assets/css/styles.css'

  export default {
    components:{
    },
    data(){
      return{
      }
    },

  }
</script>

<style>
  #app {

  }


</style>
